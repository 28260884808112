<template>
  <div>
    <Mob v-if="isMobile" />
    <Pc
      v-else
      :user="user"
      :currentUser="currentUser"
    />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { RoleText } from '@/helpers'
import { usersCollection } from '@/plugins/firebase'
import Finance from '@/components/Modals/Finance.vue'
import SelectMonth from '@/components/Modals/SelectMonth'

const Mob = () => import('./Mob.vue')
const Pc = () => import('./Pc.vue')

export default {
  name: 'IndexUserHeader',
  components: {
    Mob,
    Pc
  },
  props: {
    currentUser: {
      type: Object,
      default: {}
    },
    user: {
      type: Object,
      default: {}
    }
  },
  data: () => ({
    isMobile: isMobile,
    roleText: RoleText
  }),
  created() {},
  computed: {
    filterUserDataByMonthText() {
      let month = this.filterUserDataByMonth
      return String(new Date(month).toLocaleString('ru', {month: 'long', year: 'numeric'}))
    },
    filterUserDataByMonth: {
      get() {
        return this.$store.getters['users/getFilterUserDataByMonth']
      },
      set(val) {
        this.$store.commit('users/setFilterUserDataByMonth', val)
      }
    },
    leftToPaid() {
      let earned = this.earned

      let leftToPaid = 0

      let paid = 0
      let prepayments = this.prepayments

      if (prepayments && prepayments.length > 0) {
        prepayments.forEach(prepayment => {
          paid = Number(paid) + Number(prepayment.cost)
        })
      }

      let deductionsSumm = 0
      let deductions = this.deductions

      if (deductions && deductions.length > 0) {
        deductions.forEach(deduction => {
          deductionsSumm = Number(deductionsSumm) + Number(deduction.cost)
        })
      }

      leftToPaid = earned - paid - deductionsSumm

      return leftToPaid
    },
    earned() {
      let earned = 0

      let premiums = this.premiums
      if (premiums && premiums.length > 0) {
        premiums.forEach(premiums => {
          earned = Number(earned) + Number(premiums.cost)
        })
      }
      
      let montages = this.montages
      if (montages && montages.length > 0) {
        montages.forEach(montage => {
          if (montage.montagersInfo[this.user.uid] && montage.montagersInfo[this.user.uid].costMontage) {
            earned = Number(earned) +  Number(montage.montagersInfo[this.user.uid].costMontage)
          }
        })
      }

      let tasks = this.tasks
      if (tasks && tasks.length > 0) {
        tasks.forEach(task => {
          if (task.montagersInfo[this.user.uid] && task.montagersInfo[this.user.uid].costWork) {
            earned = Number(earned) + Number(task.montagersInfo[this.user.uid].costWork)
          }
        })
      }
			
			let orders = this.orders
      if (orders && orders.length > 0) {
        orders.forEach(order => {
					// fullCost
					let fullCost = 0
					let percent = order.percentOfOrder || 0

					if (String(percent).includes(',')) {
						percent = String(percent).replace(',', '.')
						percent = Number(percent)
					}

					order.works.forEach(work => {
						fullCost = fullCost + Number(work.cost)
					});

					earned = Number(earned) + Number((fullCost * percent) / 100)
        })
      }

      let hourlyWorks = this.hourlyWorks
      if (hourlyWorks && hourlyWorks.length > 0) {
        hourlyWorks.forEach(work => {
          if (work.hourPayWorkMasters && this.user.role != 'prodDir') {
            let ifHPWM = this.$ifHourPayWorkMasters(work, this.user)
            earned = Number(earned) + ifHPWM.cost
          } else if (work.numberOfHours) {
            let rate = 0
            if (work.rate) {
              rate = work.rate
            }
            earned = Number(earned) + (Number(work.numberOfHours) * rate)
          }
        })
      }

      return earned
    },
    hourlyWorks() {
      return this.$store.getters['hourlyWorks/getTasks']
    },
    montages() {
      return this.$store.getters['montages/getMontagesForMontager']
    },
    tasks() {
      return this.$store.getters['manufactory/getTasksForMaster']
    },
		orders() {
      return this.$store.getters['orders/getOrdersForManager']
    },
    prepayments() {
      return this.$store.getters['prepayments/getPrepayments']
    },
    deductions() {
      return this.$store.getters['deductions/getDeductions']
    },
    premiums() {
      return this.$store.getters['premiums/getPremiums']
    }
  },
  methods: {
    async selectMonth() {
      let modalMonth = await this.$showModal({
        component: SelectMonth,
        isPersistent: true,
        props: {
          title: 'Выберите месяц',
          selectMonth: this.filterUserDataByMonth
        }
      })
      if (modalMonth) {
        this.filterUserDataByMonth = modalMonth
      }
    },
    async credit(event) {
      let texts = {
        writeOff: 'Списать',
        giveOut: 'Выдать'
      }
      let summ = await this.$showModal({
        component: Finance,
        isPersistent: false,
        props: {
          title: `${texts[event]} кредит`,
          event: texts[event]
        }
      })

      let credit = this.user.credit ? this.user.credit : 0

      if (event === 'writeOff') {
        credit = Number(credit) - Number(summ)
      } else if (event === 'giveOut') {
        credit = Number(credit) + Number(summ)
      }

      this.$store.dispatch('users/updateUser', { uid: this.user.uid, data: { credit: credit } })

      if (event === 'writeOff') {
        this.$store.dispatch('deductions/createDeductions', {
          cost: summ,
          comment: 'Кредит',
          user: usersCollection.doc(this.user.uid)
        })
      }
    }
  }
}
</script>
