<template>
  <v-card>
    <v-card-title class="userTableTitle">
      <div style="font-weight: 400;">
        Задания
        <span v-if="costTasks">{{ costTasks }}</span>
      </div>
      <v-spacer />
      <div v-if="tasks.length > 0" class="d-flex align-center">
        <v-btn 
          icon
          @click="tasksPage > 1 ? pagination('prev') : null"
          :disabled="tasksPage === 1"
          small
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span style="font-size: 0.85em;">{{ tasksPage }} стр.</span>
        <v-btn 
          icon
          @click="listTasksPage.length > 0 && tasksPage < tasksLastPage ? pagination('next') : null"
          :disabled="tasks.length < 10 || tasksPage === tasksLastPage"
          small
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-divider />
    <div v-if="!tasks || tasks.length === 0" class="infoNoItems">
      Нет заданий
    </div>
    <v-simple-table :dense="!$isMobile" v-if="listTasksPage.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Дата</th>
            <th class="text-left">Клиент</th>
            <th class="text-left">Стоимость</th>
            <th class="text-left">Менеджер</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="task in listTasksPage"
            :key="task.id"
            class="tableItemList"
          >
            <td style="min-width: 80px;">{{ $formatDate(task.date.toDate()) }}</td>
            <td style="min-width: 125px;">{{ task.client.name }}</td>
            <td style="min-width: 125px;">
              {{ 
                task.montagersInfo[user.uid] 
                  ? task.montagersInfo[user.uid].costWork
                  ? `${task.montagersInfo[user.uid].costWork}р.` 
                  : 0 
                  : 0 
              }}
            </td>
            <td>{{ task.manager.displayName }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: {}
    }
  },
  methods: {
    pagination(direction) {
      if (direction === 'next' && this.tasksPage != this.tasksLastPage) {
        this.$store.commit('manufactory/setTasksForMasterPaginationPage', this.tasksPage + 1)
      } else if (direction === 'prev' && this.montagesPage != 1) {
        this.$store.commit('manufactory/setTasksForMasterPaginationPage', this.tasksPage - 1)
      }
    }
  },
  computed: {
    tasksLastPage() {
      return this.$store.getters['manufactory/getTasksForMasterLastPage']
    },
    tasksPage() {
      return this.$store.getters['manufactory/getTasksForMasterPage']
    },
    listTasksPage() {
      return this.$store.getters['manufactory/getListTasksPageForMaster']
    },
    costTasks() {
      if (!this.tasks || this.tasks.length === 0) {
        return false
      }
      let price = 0
      this.tasks.map(task => {
        if (task.montagersInfo[this.user.uid] && task.montagersInfo[this.user.uid].costWork) {
          price = price + Number(task.montagersInfo[this.user.uid].costWork)
        }
      })
      return `${price}р.`
    },
    tasks() {
      return this.$store.getters['manufactory/getTasksForMaster']
    },
  }
}
</script>