var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"userTableTitle"},[_c('div',{staticStyle:{"font-weight":"400"}},[_vm._v(" Завершённые заказы "),(_vm.costOrders)?_c('span',[_vm._v(_vm._s(_vm.costOrders))]):_vm._e()]),_c('v-spacer'),(_vm.options && _vm.pagination)?_c('v-data-footer',{staticClass:"rl-data-footer",staticStyle:{"width":"310px","padding":"0"},attrs:{"options":_vm.options,"pagination":_vm.pagination,"items-per-page-text":"","items-per-page-options":[5, 10, 15, 20, 30, 40]},on:{"update:options":function($event){_vm.options=$event}}}):_vm._e()],1),_c('v-divider'),(!_vm.orders || _vm.orders.length === 0)?_c('div',{staticClass:"infoNoItems"},[_vm._v(" Нет заказов ")]):_vm._e(),(_vm.orders && _vm.orders.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.tableHeadres,"items":_vm.orders,"items-per-page":10,"dense":!_vm.$isMobile,"options":_vm.options,"hide-default-footer":""},on:{"click:row":function($event){return _vm.editOrder($event)},"update:options":function($event){_vm.options = $event},"pagination":function($event){_vm.pagination = $event}},scopedSlots:_vm._u([{key:"item.archiveDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatDate(item.archiveDate.toDate()))+" ")]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [(item.client)?_c('span',[_vm._v(_vm._s(item.client.name))]):_c('span',[_vm._v("Не указан")])]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fullCost(item))+"р. ")]}},{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.normolizePercent(item.percentOfOrder))+"% ")]}},{key:"item.amount-work",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.fullCost(item) * _vm.normolizePercent(item.percentOfOrder)) / 100)+"р ")]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }