<template>
  <div>
    <Mob @close="close" :formData="form" :taskUser="taskUser" v-if="isMobile" />
    <Pc @close="close" :formData="form" :taskUser="taskUser" v-else />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
const Mob = () => import('./Mob.vue')
const Pc = () => import('./Pc.vue')

export default {
  components: {
    Mob,
    Pc
  },
  props: {
    task: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isMobile: isMobile
  }),
  computed: {
    form() {
      if (this.task && this.task.user) {
        return JSON.parse(JSON.stringify({
          date: this.task.date.toDate().toISOString().substr(0, 10),
          numberOfHours: this.task.numberOfHours ? Number(this.task.numberOfHours) : 0,
          numberOfHoursOnManufactory: this.task.numberOfHoursOnManufactory ? Number(this.task.numberOfHoursOnManufactory) : 0,
          numberOfHoursOnMontage: this.task.numberOfHoursOnMontage ? Number(this.task.numberOfHoursOnMontage) : 0,
          tasks: this.task.tasks,
          hourPayWorkMasters: this.task.hourPayWorkMasters || false
        }))
      }
      return null
    },
    taskUser() {
      if (this.task && this.task.user) {
        return this.task.user
      } else {
        return this.$store.getters['user/getUser']
      }
    },
  },
  methods: {
    close(data) {
      if (data) {
        return this.$emit('close', data)
      }
      return this.$emit('close')
    },
  }
}
</script>
